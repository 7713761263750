import React, { FunctionComponent, memo } from 'react';
import styles from './ExtractToFTP.module.scss';
import { getStrings } from '../../../configuration/Localization';
const { EXTRACT_TO_FTP, EXTRACT_TO_SFTP_SUBMITTED } = getStrings();

const ExtractToFTP: FunctionComponent = () => (
  <div className={styles.container}>
    <div className={styles.title}>{EXTRACT_TO_FTP}</div>
    <div>{EXTRACT_TO_SFTP_SUBMITTED}</div>
  </div>
);

export default memo(ExtractToFTP);
